export enum AppRoute {
  Home = '/',
  Comparison = '/vergleich',
  Blog = '/blog/',
  SearchLocalTutorsBySubject = '/nachhilfe/:subject',
  SearchLocalTutorsPerCity = '/:city/nachhilfe',
  SearchLocalTutorsPerCityAndSubject = '/:city/nachhilfe/:subject',
  SearchOnlineTutors = '/online-nachhilfe/:subject',
  OnlineTutoring = '/online-nachhilfe',
  SearchRefugeesPerCity = '/:city/deutsch-fuer-gefluechtete',
  SearchRefugeesOnline = '/online-deutsch-fuer-gefluechtete',
  SearchSchools = '/:city/nachhilfeschule',
  TutorDetails = '/nachhilfeangebot/:id',
  SchoolDetails = '/:city/:schoolTitle/:id',
  PublishOffer = '/nachhilfelehrer-werden',
  EditOffer = '/meinnachhilfeangebot-bearbeiten',
  AddInstituteOffer = '/nachhilfeschule-veroeffentlichen',
  EditInstituteOffer = '/nachhilfeschule-bearbeiten/:id',
  UserProfile = '/einstellungen',
  InstituteSettings = '/instituts-einstellungen',
  InstituteOffers = '/instituts-angebote',
  MyOfferRequests = '/nachrichten',
  MyOffer = '/meinnachhilfeangebot',
  MyOfferPdf = '/meinnachhilfeangebot/pdf',
  ForTutorsBecomeTutor = '/nachhilfe-geben/',
  ForStudentsTeachersNearYou = '/students/teachersnearyou',
  AboutUs = '/ueber-uns/',
  AboutTSHowDoesItWork = '/about/howdoesitwork',
  AboutTSForTutoringInstitutes = '/nachhilfeschulen',
  SupportAndHelpYouAskWeAnswer = '/hilfe/',
  SupportAndHelpContact = '/about/contact',
  FooterImpressum = '/impressum',
  FooterTOS = '/nutzungsbedingungen',
  FooterDataProtection = '/datenschutz',
  ServiceAndAdviceHowDoesItWork = '/howdoesitwork',
  GetStartedNowAdvertiseOffer = '/advertiseoffer',
  SetPassword = '/passwort_festlegen',
  NotFound = '/not-found',
  Rate = '/bewertung/:id',
  FAQ = '/hilfe/',
  Partners = '/partner',
  Sitemap = '/sitemap.html',
  SocialCall = '/gruppengespraech',
  EliteTutorOffer = '/premium-tutor-werden',
  Availability = '/verfuegbarkeit',
  UnitOverview = '/einheitenuebersicht/:viewAs(tutor|schueler)',
  Billing = '/abrechnung',
  MyPricing = '/abonnement',
  PersonalizedSubscription = '/mitgliedschaft/:token',
  Earnings = '/verdienst',
  EarnMoney = '/aktionen',
  MySubjects = '/meine-faecher',
  IntroductionCall = '/einfuehrung',
  BookEliteTutor = '/premium-tutor-buchen/:offerID',
  EliteTutorDetails = '/elite-nachhilfeangebot/:id',
  RefugeeTutorDetails = '/deutsch-hilfe/:id',
  SearchEliteTutors = '/online-lernfoerderung/:subject',
  MyPricingSuccess = '/abonnement/gebucht',
  DonationInitiative = '/spendeninitiative',
  RefugeeInitiative = '/deutsch-fuer-gefluechtete',
  TutorspacePlus = '/tutorspace-plus',
  TutorspacePremiumLegacy = '/tutorspace-premium',
  TutorspacePremiumTutoring = '/premium-nachhilfe',
  TutorspacePremiumBecome = '/online-nachhilfe-geben',
  TutorspacePremiumRegisterAsTutor = '/unterrichten',
  TutorspacePremiumRegisterAsTutorSteps = '/unterrichten/:step',
  CreditPurchase = '/einheit-kaufen/:token',
  Jobs = '/jobs/',
  News = '/aktuelles',
  Press = '/presse/',
  PatrickNadler = '/patrick-nadler',

  External_TrialHour = 'https://probestunde.tutorspace.de/reservieren2/',
  External_TrialHour_Search_Right = 'https://probestunde.tutorspace.de/reservieren2?utm_source=tutorspace&utm_medium=organic&utm_campaign=searchright',
  External_TrialHour_Search_Bottom = 'https://probestunde.tutorspace.de/reservieren2?utm_source=tutorspace&utm_medium=organic&utm_campaign=searchbottom ',
  External_TrialHour_Offer = 'https://probestunde.tutorspace.de/reservieren2?utm_source=tutorspace&utm_medium=organic&utm_campaign=offer-button',
  External_TrialHour_OnlineTutoring = 'https://probestunde.tutorspace.de/reservieren2/?utm_source=tutorspace&utm_medium=organic&utm_campaign=online-nachhilfe',
  External_TrialHour_ExitIntent = 'https://probestunde.tutorspace.de/reservieren2/?utm_source=tutorspace&utm_medium=organic&utm_campaign=probestunde-popup',
  External_HelpRefugees = 'https://ukraine.perspectivefunnel.com/helfen?utm_source=tutorspace&utm_medium=referral&utm_campaign=landingpage',
  External_FirstAidGermaninUkrainianPdf = 'https://drive.google.com/file/d/1qp67HLd3RsdgPxFHtkKPzt2RzyOkV0An/view',
  External_FirstAidGermanInRussianPdf = 'https://drive.google.com/file/d/191CardR84xkBH2L8Sc6P-_9gi_YkU2v_/view',
  External_Trustpilot_Reviews = 'https://de.trustpilot.com/review/tutorspace.de',
  External_Promotion_Tutor = 'https://bit.ly/3w9AHEq',
  External_Promotion_Seeker = 'https://bit.ly/4boEnm1',
  External_Promotion_LOCAL = 'https://nachhilfe.tutorspace.de/mithelfen/',
  External_Promotion_REGION = 'https://nachhilfe.tutorspace.de/stadtleiter/',
  EXTERNAL_ALENA = 'https://lerne-mit-alena.de',
  EXTERNAL_NACHHILFESCHULEN = 'https://nachhilfeschulen.org/',
  EXTERNAL_SPRACHCOACH = 'https://dein-sprachcoach.de/',
  EXTERNAL_BILDUNGSTALKS = 'https://bildungstalks.de/',
  EXTERNAL_BILDUNG = 'https://bildung-und-teilhabe-antrag.de/',
}
